import { AxiosResponse } from 'axios'

import { ECardType } from 'models/card/CardType'
import { IContainerCard } from 'models/card/ContainerCard'
import { IContentCard } from 'models/card/ContentCard'
import { ICards } from 'models/card/GenericCard'
import { EContentOriginSystem, ERecordType } from 'models/content/enumeration'
import { EFilterType } from 'models/filters/Enumeration'
import { TFilterItem } from 'modules/filters-panel/filters-panel'
import AxiosSearchService from 'services/AxiosSearchService'
import { getSharedOwners } from 'services/content/ContentService'

import {
  AUDIO_SHOWS_FILTER,
  SEARCH_SERVICE_FILTER_TYPE_MAP,
  VIDEO_PARENT_SHOWS_FILTER,
} from './search-service.constant'
import {
  TSearchServiceResultOrchestration,
  ESearchServiceOrchestrationItemObjectType,
  ESearchServicePathSegment,
  ISearchServiceFilter,
  IShow,
  ITag,
  SearchServiceOrchestrationContainer,
  SearchServiceOrchestrationContent,
  SearchServiceResultOrchestration,
  SearchServiceResultOrchestrationShows,
  SearchServiceResultOrchestrationTags,
} from './search-service.def'

const fetchAxiosSearchService = async (
  pathSegment: ESearchServicePathSegment,
  params?: URLSearchParams,
  tenant?: string
): Promise<AxiosResponse> =>
  AxiosSearchService.Instance.get(pathSegment, { params, headers: { ...(tenant && { 'x-pfu-tenant': tenant }) } })

export const searchOrchestration = async (params: URLSearchParams, tenant?: string): Promise<ICards> => {
  const response: AxiosResponse<SearchServiceResultOrchestration> = await fetchAxiosSearchService(
    ESearchServicePathSegment.ORCHESTRATION,
    params,
    tenant
  )

  return mapSearchServiceResponseDataToCards(response.data)
}

export const searchOrchestrationTags = async (): Promise<ITag[]> => {
  const response: AxiosResponse<SearchServiceResultOrchestrationTags> = await fetchAxiosSearchService(
    ESearchServicePathSegment.ORCHESTRATION_TAGS
  )

  return mapSearchServiceResponseDataToTags(response.data)
}

export const searchOrchestrationVideoParentShows = async (query: string, tenant?: string): Promise<IShow[]> => {
  const params = buildSearchServiceParams(query, [VIDEO_PARENT_SHOWS_FILTER])

  const response: AxiosResponse<SearchServiceResultOrchestrationShows> = await fetchAxiosSearchService(
    ESearchServicePathSegment.ORCHESTRATION,
    params,
    tenant
  )

  return mapSearchServiceResponseDataToShows(response.data)
}

export const searchOrchestrationAudioShows = async (query: string, tenant?: string): Promise<IShow[]> => {
  const params = buildSearchServiceParams(query, [AUDIO_SHOWS_FILTER])

  const response: AxiosResponse<SearchServiceResultOrchestrationShows> = await fetchAxiosSearchService(
    ESearchServicePathSegment.ORCHESTRATION,
    params,
    tenant
  )

  return mapSearchServiceResponseDataToShows(response.data)
}

export const mapSearchServiceResponseDataToTags = (data: SearchServiceResultOrchestrationTags): ITag[] =>
  data.tags.map((tag: string) => ({ value: tag }))

export const mapSearchServiceResponseDataToShows = (data: SearchServiceResultOrchestrationShows): IShow[] =>
  data.results.map((result) => ({
    activationDate: result.activationDate ? Math.round(new Date(result.activationDate).getTime() / 1000) : '',
    expirationDate: result.expirationDate ? Math.round(new Date(result.expirationDate).getTime() / 1000) : '',
    imageUrl: result.imageUrl,
    modificationDate: result.modificationDate,
    name: result.name,
    objectID: result.id,
    objectType: ECardType.CONTENT,
    originSystem: result.originSystem,
    recordType: result.recordType,
    source: result.source,
    status: result.status,
    type: result.type,
    owner: result.owner,
  }))

export const mapSearchServiceResponseDataToCards = (data: SearchServiceResultOrchestration): ICards => {
  return {
    total: data.totalCount,
    cards: data.results.map((result: TSearchServiceResultOrchestration) => {
      switch (result.objectType) {
        case ESearchServiceOrchestrationItemObjectType.CONTENT:
          return mapSearchServiceResponseDataToContentCard(result)

        case ESearchServiceOrchestrationItemObjectType.CONTAINER:
          return mapSearchServiceResponseDataToContainerCard(result)

        default:
          return undefined
      }
    }),
  }
}

const mapSearchServiceResponseDataToContentCard = (content: SearchServiceOrchestrationContent): IContentCard => {
  return {
    activationDate: content.activationDate,
    cardType: ECardType.CONTENT,
    episodeNumber: content?.episodeNumber,
    expirationDate: content.expirationDate,
    id: content.id,
    imageUrl: content.imageUrl,
    modificationDate: Date.parse(content.modificationDate),
    name: content.name,
    originSystem: content.originSystem,
    recordType: content.recordType,
    seasonNumber: content?.seasonNumber,
    showName: content?.showName,
    source: content.source,
    status: content.status,
    type: content.type,
    typology: content.typology,
    slug: content?.slug,
    owner: content?.owner,
    sharedOwners: getSharedOwners(content?.tenantMappings.map((tenantMapping) => tenantMapping.tenant)),
  }
}

const mapSearchServiceResponseDataToContainerCard = (
  container: SearchServiceOrchestrationContainer
): IContainerCard => {
  return {
    cardType: ECardType.CONTAINER,
    id: container.id,
    modificationDate: Date.parse(container.modificationDate),
    name: container.name,
    originSystem: EContentOriginSystem.PFU,
    recordType: container.recordType,
    source: container.source,
    status: container.status,
    type: container.type,
    slug: container?.slug,
    owner: container?.owner,
  }
}

export const addMissingFilters = (filters: TFilterItem[]): TFilterItem[] => {
  if (filters.some((filter) => filter.name === ERecordType.RADIO_BONUS)) {
    filters.push({ type: EFilterType.TYPE, name: ERecordType.PODCAST_BONUS, isSelected: true })
  }

  if (filters.some((filter) => filter.name === ERecordType.RADIO_TRAILER)) {
    filters.push({ type: EFilterType.TYPE, name: ERecordType.PODCAST_TRAILER, isSelected: true })
  }

  if (filters.some((filter) => filter.name === ERecordType.PAGE_HOME)) {
    filters.push({ type: EFilterType.TYPE, name: ERecordType.PAGE_CATCH_UP, isSelected: true })
    filters.push({ type: EFilterType.TYPE, name: ERecordType.CUSTOM_PAGE, isSelected: true })
  }

  if (filters.some((filter) => filter.name === ERecordType.FEED_STANDARD)) {
    filters.push({ type: EFilterType.TYPE, name: ERecordType.FEED_PRIMARY_CATEGORY, isSelected: true })
    filters.push({ type: EFilterType.TYPE, name: ERecordType.PERSONALIZED_FEED, isSelected: true })
  }

  if (filters.some((filter) => [EFilterType.RECOMMANDABLE, EFilterType.LINKED_CONTENT].includes(filter.type))) {
    ;[ERecordType.COMBO, ERecordType.HERO, ERecordType.LIST, ERecordType.MOSAIC, ERecordType.STANDARD].forEach(
      (name) => {
        if (!filters.some((f) => f.name == name)) {
          filters.push({ type: EFilterType.TYPE, name: name, isSelected: true })
        }
      }
    )
  }

  return filters
}

const mapFiltersToSearchServiceFilters = (filters: TFilterItem[]): ISearchServiceFilter[] => {
  return filters.map((filter) => {
    switch (filter.type) {
      case EFilterType.LINKED_CONTENT:
      case EFilterType.RECOMMANDABLE:
        return { ...filter, type: SEARCH_SERVICE_FILTER_TYPE_MAP[filter.type], name: 'true' }
      case EFilterType.EMBARGO:
      case EFilterType.EXPIRED:
        return { type: SEARCH_SERVICE_FILTER_TYPE_MAP[filter.type], name: 'true' }
      default:
        return { ...filter, type: SEARCH_SERVICE_FILTER_TYPE_MAP[filter.type] }
    }
  })
}

export const buildSearchServiceParams = (query: string, filters: TFilterItem[]): URLSearchParams => {
  const searchServiceParams = new URLSearchParams()

  if (query) {
    searchServiceParams.append('query', query)
  }

  const searchServiceFilters: ISearchServiceFilter[] = mapFiltersToSearchServiceFilters(filters)

  searchServiceFilters.forEach((filter) => searchServiceParams.append(filter.type, filter.name))

  return searchServiceParams
}
